.grecaptcha-badge {
  z-index: 9999;
}

iframe {
  border: 0;
  min-width: 0;
}

.ellipse {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 2rem);
}

.material-icons-two-tone {
  color: transparent;
  background-clip: text;

  &.pending,
  .pending & {
    background-color: $primary;
  }

  &.active,
  .active & {
    background-color: $success;
  }

  &.locked,
  .locked & {
    background-color: $primary;
  }

  &.archived,
  .archived & {
    background-color: $dark;
  }

  &.warning,
  .warning & {
    background-color: $orange-600;
  }

  &.alert,
  .alert & {
    background-color: $red-600;
  }

  small & {
    font-size: 0.8rem;
    margin-top: 0.2rem;
  }
}

body {
  height: 100vh;
  touch-action: pan-x pan-y;
}

small,
.small-text {
  font-size: 0.8em;
  line-height: 0.8rem;
  font-weight: normal;
  display: inline-block;
}

.light-text {
  color: #84868f;
}

.lighter-text {
  color: #bbb;
}

.btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &__shrink {
    transition: all 0.2s ease-in-out;
    gap: 0;

    &-label {
      transition: all 0.2s ease-in-out;
      opacity: 0;
      max-width: 0;
      margin: 0;
      padding: 0;
    }

    &:hover {
      gap: 0.5rem;

      & .btn__shrink-label {
        opacity: 1;
        max-width: 500px;
      }
    }

    .btn-group:hover & {
      gap: 0.5rem;

      & .btn__shrink-label {
        opacity: 1;
        max-width: 500px;
      }
    }
  }

  &:disabled {
    filter: grayscale(100);
  }
}

// .btn:not(.no-hover) {
//   &:hover,
//   &:active {
//     color: #fff !important;

//     .material-icons-two-tone {
//       background-color: #fff !important;
//     }
//   }
// }

.btn:not([class*='btn-outline']) {
  color: #fff !important;

  .material-icons-two-tone {
    background-color: #fff !important;
  }
}

.page-heading {
  font-size: 1.6rem;
  margin-bottom: 2rem;

  i.fa-chevron-right {
    font-size: 0.8rem;
    margin: -2px 10px 0;
    vertical-align: middle;
  }
}

.page-sub-heading {
  font-size: 1.3rem;
  margin-bottom: 1rem;
  color: var(--bs-gray-600);

  i.fa-chevron-right {
    font-size: 0.8rem;
    margin: -2px 10px 0;
    vertical-align: middle;
  }

  @include media-breakpoint-down(sm) {
    font-size: 1.1rem;
  }
}

.page-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;

  .page-search {
    width: 100%;
    display: block;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    padding: 1rem 1.5rem;
    align-items: center;
    justify-content: stretch;
    margin-bottom: 0;

    & > div {
      flex: 1;
      width: 100%;
      gap: 1rem;
    }

    & > * {
      z-index: 2;
    }

    .btn {
      flex-grow: 1;
      width: 100%;
    }

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      top: 100%;
      left: 0;
      position: absolute;
      box-shadow: 0 0 50px 100px rgba(236, 236, 236);
      z-index: 1;
    }
  }
}

.fancy-list {
  overflow: hidden;
  border-radius: 23px;
  border: $input-border-width solid $input-border-color;
  background-color: white;
  font-size: 0.9rem;
  font-weight: 600;

  .form & {
    border-radius: 14px;
  }

  label {
    margin-bottom: 0rem;
    font-size: 0.7rem;
  }

  & > div {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: row;
    gap: 1rem;
    padding: 1.5rem;
    min-height: 50px;
    border-bottom: $input-border-width solid $input-border-color;

    label {
      color: var(--bs-gray-500);
    }

    &:last-child {
      border: none;
    }

    &.selected {
      background-color: rgba($primary, 0.1);
    }

    &.disabled {
      background-color: $light;
      color: #84868f;

      label {
        color: lighten(#84868f, 20%);
      }
    }

    &:hover {
      background-color: #f6f6f6;
      //color: var(--bs-gray-600);

      label {
        //color: var(--bs-gray-500);
      }
    }

    & > div.inset {
      padding: 1rem 0;
      border-bottom: 1px solid var(--bs-gray-200);
      margin-right: -1.5rem;
      padding-right: 1.5rem;

      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }

  &__vertical {
    div {
      flex-direction: column;
      gap: 0;
    }
  }

  &__borderless {
    border: none;

    & > div {
      padding: 1rem 0;
      color: #555;
    }
  }
}

.flex {
  display: flex;

  &-column {
    flex-direction: column;
  }

  &-row {
    flex-direction: row;
  }

  &-justify-end {
    flex-wrap: wrap;
    justify-content: end;
  }

  &-justify-center {
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
  }
}

.flex-grow > * {
  flex: 1;
}

.has-tools {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  .tools {
    display: flex;
    align-items: center;
    gap: 0.7rem;
  }
}

.has-content {
  flex-wrap: wrap;

  .content {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
}

.card--milestone {
  background: #f8f8f8;
  border: none;
  border-radius: 0;
  box-shadow: none;

  .table > :not(caption) > * > * {
    border-bottom-color: rgb(41 48 66 / 20%);
  }

  .table tr:last-child td {
    border-bottom: 0 !important;
  }

  .table tr td:first-child {
    padding-left: 65px;
  }
}

.version--toggle {
  display: flex;
  align-items: center;
  gap: 1.5rem;

  i {
    position: absolute;
    padding: 18px;
    margin-left: -15px;
    left: 15px;
    cursor: pointer;
  }
}

.version--open > td:first-of-type,
.version--closed > td:first-of-type {
  padding-left: 44px;
}

.milestone__counter {
  font-size: 11px;
  background: rgba(41, 48, 66, 0.19);
  line-height: 1;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  border-radius: 11px;
  font-weight: $font-weight-bold;
}

.linked-subarea__names {
  &:not(:last-child):after {
    content: ', ';
  }
}

.steps-header {
  padding: 1.2rem 0;
  font-weight: $font-weight-bold;
  display: grid;
  grid-template-columns: max-content 20px max-content 20px max-content 20px max-content;
  align-items: center;
  justify-content: center;

  span {
    height: 2px;
    background: $dark;
    width: 100%;
    opacity: 0.34;
  }

  &__step {
    padding: 5px 9px;
    border: 2px solid $dark;
    border-radius: 3px;
    line-height: 1;
    font-size: 0.95rem;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.34;

    &.active {
      opacity: 1;

      + span {
        opacity: 1;
      }
    }
  }
}

@keyframes highlight-cell {
  0% {
    background-color: rgba($primary, 0.15);
  }
  100% {
    background-color: #ffffff00;
  }
}

.table {
  &.highlighted {
    td {
      &.highlight {
        animation: highlight-cell 5s ease-in-out;
      }
    }
  }
}

.divider {
  display: flex;
  border-top: 1px solid #ddd;
  position: relative;
  justify-content: center;
  line-height: 24px;
  width: 60%;
  margin: 0 auto;
  background-color: inherit;

  &::before {
    content: attr(data-label);
    background-color: var(--bs-body-bg);
    margin: 0 auto;
    font-size: 10px;
    line-height: 12px;
    padding: 0 10px;
    margin-top: -6px;
    font-weight: 400;
    text-transform: uppercase;
  }
}

.page-divider {
  border-top: 1px solid #ddd;
  position: relative;
  width: calc(100% + 3rem);
  margin: 3rem 0 3rem;
  margin-left: -1.5rem;
  background-color: inherit;

  @include media-breakpoint-up(sm) {
    width: calc(100% + 8rem);
    margin-left: -4rem;
  }
}

.grid {
  position: relative;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));

  &-col-4 {
    grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
  }

  &-col-5 {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  &-col-1\/3 {
    grid-template-columns: 33% 1fr;
  }

  &-col-1\/2 {
    grid-template-columns: 1fr 1fr;
  }

  @include media-breakpoint-down(sm) {
    display: flex;
    flex-direction: column;
  }
}

input {
  position: relative;
  z-index: 2;
  transition: all 0.2s ease-in-out;

  &.invalid {
    padding: 0.5rem 1.3rem 1.5rem;
    background-color: rgba($danger, 0.02);
  }

  &[disabled] {
    color: lighten(#84868f, 20%);
  }

  &:focus {
    outline: 2px solid rgba($primary, 0.3);
  }
}

.toggle-control {
  display: flex;
  height: 22px;
  width: 35px;
  cursor: pointer;

  &-track {
    background-color: #555;
    height: 100%;
    flex-grow: 1;
    display: flex;
    border: 1px solid #ddd;
    border-radius: 100px;
    padding: 2px;
    position: relative;
    transition: all 0.2s ease-in-out;
  }

  &-thumb {
    position: absolute;
    top: 2px;
    left: 0;
    transform: translateX(2px);
    right: auto;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    transition: all 0.2s ease-in-out;
    background-color: #999;
  }

  &.on &-track {
    background-color: $primary;
  }

  &.on &-thumb {
    transform: translateX(calc(100% - 1px));
    background-color: #efefef;
  }
}

.grid-skeleton {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    box-shadow: inset -200px -200px 100px -100px var(--bs-body-bg);
  }
}

.home-card {
  height: 95px;
}

.home_available-connectors {
  .card {
    position: relative;

    &::after {
      content: '+';
      display: flex;
      justify-content: end;
      align-items: center;
      padding: 2rem;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      text-decoration: none;
      font-size: 40px;
      font-weight: 600;
      color: lighten($primary, 10%);
      box-shadow: inset -400px 0 250px -250px #ffffff;
      opacity: 0;
      transform: translateX(50px);
      transition: all 0.2s ease-in-out;
    }

    &:hover::after {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

.btn-sort-width {
  width: 135px;
}
