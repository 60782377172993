.version.version--open td {
  background: #F8F8F8;
}

.version.version--closed td {
  background: #fff;
}

.card--milestone.version--opened {
  display: flex;
}

.card--milestone.version--closed {
  display: none !important;
}
