.card {
  &__linking-container {
    display: grid;
    grid-template-columns: 1fr 50px 1fr;

    &--left, &--right {

    }
  }
}

.form-check__container {
  overflow: hidden;
  overflow-y: auto;

  .form-check {
    position: relative;
  }

  &--left {
    max-height: calc(100vh - 587px);
  }

  &--right {
    max-height: calc(100vh - 590px);
  }

  .form-check {
    padding: 0.3rem 0 0.3rem 2.6rem;

    &:first-child {
      padding-top: 1rem;
    }

    &:last-child {
      padding-bottom: 1rem;
    }
  }
}
.form-check__active-indicator {

  .form-check-input:checked {
    background-color: $success;
    border-color: $success;
  }

  &:before {
    content: "•";
    position: absolute;
    right: 1.5rem;
    transform: scale(1.5);
    color: #2E8F4F;
  }

  &:after {
    content: "";
    background: rgba(red($success), green($success), blue($success),.22);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
  }
}

.form-check-input:checked {
}

.form-check-input, .form-check-label {
  position: relative;
}