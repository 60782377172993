.card--height-limit {
  max-height: calc(100vh - 280px);
  overflow: hidden;
  overflow-y: auto;

  &.no-overflow {
    overflow: unset;
  }

  &.height-limit--security-groups {
    max-height: calc(100vh - 248px);
  }
}

.card-body--height-limit {
  max-height: calc(100vh - 280px);
  overflow: hidden;
  overflow-y: auto;
  padding: 0;
}

.table {
  margin: 0;

  thead {
    border-top: none !important;
  }
}

.table .tools {
  display: flex;
  gap: 0.6rem;
  align-items: center;
}

.table--no-head-border > tbody {
  border-top: none !important;
}

.table--no-foot-border tr:last-child td {
  border-bottom: none !important;
}

.table-header {
  th {
    background: #fff;
    position: sticky;
    top: 0;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      box-shadow: 0 0 10px;
      height: 10px;
      box-shadow: 0 10px 20px -25px #333;
      height: 100%;
      border-bottom: solid 1px #ddd;
      margin-bottom: -1px;
      z-index: 1;
    }

    * {
      z-index: 2;
      position: relative;
    }
  }
}

.table-footer {
  tr {
    background: #fff;
    position: sticky;
    bottom: -2px;
    z-index: 1;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      box-shadow: 0 0 10px;
      height: 10px;
      box-shadow: 0 0 20px -15px #333;
      height: 100%;
      border-top: solid 1px #ddd;
      z-index: 1;
    }

    div {
      z-index: 2;
      position: relative;
    }
  }
}

.card-list-heading {
  border-bottom: 1px solid rgba(41, 48, 66, 0.2);
}
