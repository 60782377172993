// Select
//
// Replaces the browser default select with a custom one, mostly pulled from
// https://primer.github.io/.

.form-select {
  display: block;
  width: 100%;
  padding: 0 1.5rem;
  font-family: $form-select-font-family;
  @include font-size($form-select-font-size);
  font-weight: $form-select-font-weight;
  line-height: $form-select-line-height;
  color: $form-select-color;
  background-color: $form-select-bg;
  background-image: escape-svg($form-select-indicator);
  background-repeat: no-repeat;
  background-position: $form-select-bg-position;
  background-size: $form-select-bg-size;
  border: $form-select-border-width solid $form-select-border-color;
  //@include border-radius($form-select-border-radius, 0);
  border-radius: 14px;
  appearance: none;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 0 0 $input-focus-border-color;
  outline: none !important;
  height: 50px;

  &:focus {
    box-shadow: 0 0 0 2px $input-focus-border-color;
  }

  &[multiple],
  &[size]:not([size='1']) {
    padding-right: $form-select-padding-x;
    background-image: none;
  }

  &:disabled {
    color: $form-select-disabled-color;
    background-color: $form-select-disabled-bg;
    border-color: $form-select-disabled-border-color;
  }

  // Remove outline from select box in FF
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $form-select-color;
  }

  option {
    font-size: 1rem;
  }
}

.form-select-sm {
  padding-top: $form-select-padding-y-sm;
  padding-bottom: $form-select-padding-y-sm;
  padding-left: $form-select-padding-x-sm;
  @include font-size($form-select-font-size-sm);
  @include border-radius($form-select-border-radius-sm);
}

.form-select-lg {
  padding-top: $form-select-padding-y-lg;
  padding-bottom: $form-select-padding-y-lg;
  padding-left: $form-select-padding-x-lg;
  @include font-size($form-select-font-size-lg);
  @include border-radius($form-select-border-radius-lg);
}
