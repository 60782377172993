.welcome-buttons-container {
  display: grid;
  grid-template-columns: 244px 244px;
  gap: 1.3rem;
  justify-content: center;

  .btn {
    width: 100%;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
    text-align: center;
    justify-content: center;

    i {
      transform: scale(1.5);
    }
  }

}

.welcome-buttons-container-no-outline {
  // display: grid;
  grid-template-columns: 244px 244px;
  gap: 1.3rem;
  justify-content: center;

  .btn {
    width: 100%;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
    text-align: center;
    justify-content: center;

    i {
      transform: scale(1.5);
    }
  }

}

.welcome-buttons-container__single-item {
  display: grid;
  grid-template-columns: 244px;
  gap: 1.3rem;
  justify-content: center;

  .btn {
    width: 100%;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
    text-align: center;
    justify-content: center;

    i {
      transform: scale(1.5);
    }
  }

}