.status-label {
  display: inline-block;
  align-items: center;
  gap: 0.5rem;
  font-weight: $font-weight-bold;
  font-size: 10px;
  background: rgba($dark, 0.15);
  border: solid 1px rgba($dark, 0.15);
  color: $dark;
  padding: 10px 12px;
  margin: 2px;
  border-radius: 6px;
  line-height: 1;
  text-transform: uppercase;
  width: max-content;

  &.pending {
    background: rgba($primary, 0.15);
    color: darken($primary, 10%);
    border: solid 1px rgba($primary, 0.15);
  }

  &.active {
    background: rgba($success, 0.15);
    color: darken($success, 10%);
    border: solid 1px rgba($success, 0.15);
  }

  &.locked {
    background: rgba($primary, 0.15);
    color: darken($primary, 10%);
    border: solid 1px rgba($primary, 0.15);
  }

  &.archived {
    background: rgba($dark, 0.15);
    color: $dark;
    border: solid 1px rgba($dark, 0.15);
  }

  &.warning {
    background: rgba($orange-600, 0.15);
    color: $orange-600;
    border: solid 1px rgba($orange-600, 0.15);
  }

  &.error {
    background: rgba($red-600, 0.15);
    color: $red-600;
    border: solid 1px rgba($red-600, 0.15);
  }

  small & {
    padding: 4px 6px;
    font-size: 9px;
    font-weight: 600;
    border-radius: 3px;

    &:first-child {
      margin-left: 0;
    }
  }

  &-stack {
    display: flex;
    flex-direction: row;
    transition: all 0.2s 0.2s ease-in-out;
    align-items: center;

    tr & {
      justify-content: flex-end;
    }

    .status-label {
      width: 40px;
      height: 40px;
      font-size: 0.8rem;
      font-weight: 600;
      align-content: center;
      justify-content: center;
      overflow: hidden;
      text-overflow: clip;
      flex-flow: nowrap;
      white-space: nowrap;
      background: rgba($dark, 0.15);
      color: white;
      border: solid 1px white;
      border-radius: 42px;
      user-select: none;
      display: flex;
      padding: 4px 6px;
      margin-left: -20px;
      border: 2px solid white;

      span {
        display: inline-block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis !important;
      }

      &:first-child {
        margin-left: 0px;
      }

      &.additional {
        background-color: #ddd;
        color: #666;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        justify-content: center;
        font-weight: 700;
        font-size: 10px;
        margin-left: -10px;
      }

      &.assign {
        font-size: 10px;
        border: solid 1px #4285f4;
        background-color: #fff !important;
        color: #4285f4 !important;
        margin-left: auto;
        box-shadow: none;
        transition: all 0.2s ease-in-out;
        width: auto;
        cursor: pointer;
        height: 30px;

        &:hover {
          color: #fff !important;
          background-color: #4285f4 !important;
        }
      }
    }

    // .status-labels {
    //   display: inline-flex;

    //   .status-label {
    //     &:not(:first-of-type) {
    //       margin-left: -40px;
    //       box-shadow: -5px 0 5px -5px #333;
    //     }
    //   }
    // }

    .fa {
      cursor: pointer;

      &-left {
        margin-right: 5px;
      }

      &-right {
        margin-left: 5px;
      }
    }

    .btn {
      margin-top: 10px;
    }
  }
}
