//
// Base styles
//

.card-wrapper {
  position: relative;
  overflow: hidden;
  @include border-radius($card-border-radius);
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-width: 0; // See https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106
  width: 100%;
  height: $card-height;
  word-wrap: break-word;
  background-color: $card-bg;
  background-clip: border-box;
  border: $card-border-width solid $card-border-color;
  //@include border-radius($card-border-radius);
  border-radius: 23px;
  // @include box-shadow($card-box-shadow);
  padding: 1.5rem;
  overflow: hidden;

  > hr {
    margin-right: 0;
    margin-left: 0;
  }

  > .list-group {
    border-top: inherit;
    border-bottom: inherit;

    &:first-child {
      border-top-width: 0;
      @include border-top-radius($card-inner-border-radius);
    }

    &:last-child {
      border-bottom-width: 0;
      @include border-bottom-radius($card-inner-border-radius);
    }
  }

  // Due to specificity of the above selector (`.card > .list-group`), we must
  // use a child selector here to prevent double borders.
  > .card-header + .list-group,
  > .list-group + .card-footer {
    border-top: 0;
  }

  &.card-skeleton {
    @keyframes pulse {
      from {
        opacity: 0.2;
      }
      to {
        opacity: 1;
      }
    }

    cursor: default;
    animation: pulse 1.35s ease-in-out infinite alternate;
    background-color: #00000022;
  }

  &:hover {
    .card-tag {
      padding: 2px 45px;
    }
  }

  &-tag {
    position: absolute;
    bottom: 0;
    right: 0;
    border-top-left-radius: 15px;
    padding: 2px 25px;
    background-color: #99999922;
    font-size: 0.6rem;
    font-weight: 700;
    color: #777;
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
  }

  &-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    background-color: rgba(white, 0.2);
    backdrop-filter: blur(3px);
    z-index: 999;
    box-shadow: inset 0 0 50px rgba(white, 0.3);
    @include border-radius($card-border-radius);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    font-weight: 600;
  }

  &-button {
    max-width: 250px;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

    &:hover {
      transform: translateY(-4px);
      box-shadow: 0 10px 20px -10px #333;
    }
  }

  &-interactive {
    transition: all 0.2s ease-in-out;
    box-shadow: 0 0 0 1px #fff;

    &:hover {
      box-shadow: 0 0 0 3px lighten($primary, 10%);
    }

    &.disabled {
      background-color: #dfdfdf;
      box-shadow: none !important;
    }
  }

  &-sm {
    font-size: 0.9rem;
    gap: 1rem;
  }

  &-placeholder {
    color: lighten($dark, 60%);
    border: 2px dashed lighten($dark, 60%);
    background-color: #ffffff00;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none !important;
    transition: all 0.2s ease-in-out;

    .material-icons-two-tone {
      background-color: lighten($dark, 60%);
      font-size: 2rem !important;
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      color: lighten($dark, 50%);
      border: 2px dashed lighten($dark, 50%);

      .material-icons-two-tone {
        background-color: lighten($dark, 50%);
      }
    }
  }
}

.card-body {
  flex: 1 1 auto;
  flex-direction: column;
  padding: 3rem;
  color: $card-color;

  .card-sm & {
    padding: 0;
  }
}

.card-title {
  margin-bottom: $card-title-spacer-y;
}

.card-subtitle {
  margin-top: -$card-title-spacer-y * 0.5;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link {
  &:hover {
    text-decoration: if($link-hover-decoration == underline, none, null);
  }

  + .card-link {
    margin-left: $card-spacer-x;
  }
}

.card-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  padding: $card-cap-padding-y $card-cap-padding-x;
  font-weight: 600;

  &:first-child {
    @include border-radius($card-inner-border-radius $card-inner-border-radius 0 0);
  }

  .card-sm & {
    padding: 0;
  }
}

.card-footer {
  padding: $card-cap-padding-y $card-cap-padding-x;
  color: $card-cap-color;
  border-top: $card-border-width solid $card-border-color;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 0.5rem;

  &:last-child {
    @include border-radius(0 0 $card-inner-border-radius $card-inner-border-radius);
  }

  .card-sm & {
    padding: 0;
  }
}

.card-header-tabs {
  margin-right: -$card-cap-padding-x * 0.5;
  margin-bottom: -$card-cap-padding-y;
  margin-left: -$card-cap-padding-x * 0.5;
  border-bottom: 0;

  @if $nav-tabs-link-active-bg != $card-bg {
    .nav-link.active {
      background-color: $card-bg;
      border-bottom-color: $card-bg;
    }
  }
}

.card-header-pills {
  margin-right: -$card-cap-padding-x * 0.5;
  margin-left: -$card-cap-padding-x * 0.5;
}

// Card image
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: $card-img-overlay-padding;
  @include border-radius($card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
}

.card-img,
.card-img-top {
  @include border-top-radius($card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  @include border-bottom-radius($card-inner-border-radius);
}

//
// Card groups
//

.card-group {
  // The child selector allows nested `.card` within `.card-group`
  // to display properly.
  > .card {
    margin-bottom: $card-group-margin;
  }

  @include media-breakpoint-up(sm) {
    display: flex;
    flex-flow: row wrap;
    // The child selector allows nested `.card` within `.card-group`
    // to display properly.
    > .card {
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0%;
      margin-bottom: 0;

      + .card {
        margin-left: 0;
        border-left: 0;
      }

      // Handle rounded corners
      @if $enable-rounded {
        &:not(:last-child) {
          @include border-end-radius(0);

          .card-img-top,
          .card-header {
            // stylelint-disable-next-line property-disallowed-list
            border-top-right-radius: 0;
          }
          .card-img-bottom,
          .card-footer {
            // stylelint-disable-next-line property-disallowed-list
            border-bottom-right-radius: 0;
          }
        }

        &:not(:first-child) {
          @include border-start-radius(0);

          .card-img-top,
          .card-header {
            // stylelint-disable-next-line property-disallowed-list
            border-top-left-radius: 0;
          }
          .card-img-bottom,
          .card-footer {
            // stylelint-disable-next-line property-disallowed-list
            border-bottom-left-radius: 0;
          }
        }
      }
    }
  }
}
