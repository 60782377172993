.spinner {
  animation: rotation 1.35s linear infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
.circle {
  stroke-dasharray: 180;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: turn 1.35s ease-in-out infinite;
}
@keyframes turn {
  0% {
    stroke-dashoffset: 180;
  }
  50% {
    stroke-dashoffset: 45;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 180;
    transform: rotate(450deg);
  }
}
svg.spinner {
  width: 30px;
  height: 30px;
  &.primary {
    stroke: $dark;
  }
}



